import React from "react";
import Seo from "../Seo";
import BaseLanding from "./BaseLanding";

const JsLanding = () => {
  const language = "javascript";
  const defaultCode = `const credits = {
    "url": "js.okboomer.sh",
    "desc": "A fast and zero-persistence javascript note taking",
    "author": "@okboomersh ⚡"
}`;

  return (
    <>
      <Seo
        title="Javascript Online Editor ~ okboomer.sh"
        desc="A quick and online javascript note taking. Nothing more. With it you can take js notes without persistence. Give it a try bro!"
        keywords="js, online, javascript editor, note, note taking"
        canonical="https://js.okboomer.sh/"
        code="js"
      />
      <BaseLanding defaultCode={defaultCode} language={language} />
    </>
  );
};
export default JsLanding;
