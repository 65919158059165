import React, { useState, useEffect } from "react";
import Editor, { loader } from "@monaco-editor/react";
import okboomerTheme from "../themes/okboomer-theme.json";

const CodeEditorWindow = ({ language, code, options }) => {
  const [value] = useState(code || "");
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);

  useEffect(() => {
    loader
      .init()
      .then((monaco) => {
        monaco.editor.defineTheme("okboomer-theme", okboomerTheme);
        setIsThemeLoaded(true);
      })
      .catch((error) =>
        console.error(
          "An error occurred during initialization of Monaco: ",
          error
        )
      );
  }, []);

  return (
    <div className="overlay overflow-hidden w-full h-full shadow-4xl">
      <Editor
        height="100vh"
        language={language || "javascript"}
        value={value}
        theme={isThemeLoaded ? "okboomer-theme" : "vs-dark"}
        defaultValue=""
        options={options}
        loading={<></>}
      />
    </div>
  );
};

export default CodeEditorWindow;
