import React from "react";
import Seo from "../Seo";
import BaseLanding from "./BaseLanding";

const MdLanding = () => {
  const language = "markdown";

  const defaultCode = `# md.okboomer.sh
## A fast and zero-persistence markdown note taking

### @okboomersh ⚡
`;

  return (
    <>
      <Seo
        title="Markdown Online Editor ~ okboomer.sh"
        desc="A quick and online markdown note taking. Nothing more. With it you can take md notes without persistence. Give it a try bro!"
        keywords="md, online, markdown editor, note, note taking"
        canonical="https://md.okboomer.sh/"
        code="md"
      />
      <BaseLanding defaultCode={defaultCode} language={language} />
    </>
  );
};
export default MdLanding;
