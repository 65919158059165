import React from "react";
import Seo from "../Seo";
import BgParticles from "../BgParticles";
import "../../App.css";

const EditorsLanding = () => {
  const links = [
    {
      name: "JSON",
      url: "https://json.okboomer.sh",
      extension: ".json",
    },
    {
      name: "Python",
      url: "https://py.okboomer.sh",
      extension: ".py",
    },
    {
      name: "TypeScript",
      url: "https://ts.okboomer.sh",
      extension: ".ts",
    },
    {
      name: "Text",
      url: "https://txt.okboomer.sh",
      extension: ".txt",
    },
    {
      name: "JavaScript",
      url: "https://js.okboomer.sh",
      extension: ".js",
    },
    {
      name: "Markdown",
      url: "https://md.okboomer.sh",
      extension: ".md",
    },
  ];

  return (
    <>
      <Seo
        title="Code Online Editors ~ okboomer.sh"
        desc="A quick and online code editors and note taking. Nothing more. With it you can take ts notes without persistence. Give it a try bro!"
        keywords="typescript editor, text editor, json editor, js editor, note taking"
        canonical="https://editors.okboomer.sh/"
        code="editors"
      />
      <BgParticles />
      <div className="relative flex flex-col items-center justify-center h-screen z-10">
        <div className="flex flex-col items-center space-y-1">
          {links.map((item, index) => (
            <div
              key={index}
              className="
                text-white
                focus:outline-none 
                focus:ring-1 
                focus:ring-primary/50 
                cursor-pointer 
                hover:text-[#ff1c71]
                hover:underline
                transition-colors
                duration-300
                ff-monospace
                text-xs
              "
            >
              <a
                href={item.url}
                title={`${item.name} Online Editor ~ okboomer.sh`}
                alt={`${item.name} Online Editor ~ okboomer.sh`}
              >
                {item.extension}
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EditorsLanding;
