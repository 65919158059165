import React from "react";
import BgParticles from "../BgParticles";
import CodeEditorWindow from "../CodeEditorWindow";

const BaseLanding = ({ defaultCode, language }) => {
  const options = {
    readOnly: false,
    minimap: { enabled: false },
    lineNumbers: "off",
    scrollbar: {
      vertical: "hidden",
      horizontal: "hidden",
      verticalScrollbarSize: 0,
      horizontalScrollbarSize: 0,
      verticalSliderSize: 0,
    },
    padding: {
      top: 20,
      bottom: 20,
    },
  };

  const links = [
    {
      name: "/editors",
      url: "https://editors.okboomer.sh",
      title: "Code Editors Online ~ okboomer.sh",
    },
    {
      name: "/whoami",
      url: "https://okboomer.sh/whoami",
      title: "Who am I? ~ okboomer.sh",
    },
  ];

  return (
    <>
      <BgParticles />
      <div className="flex flex-row justify-center items-center h-screen bg-transparent">
        <div className="flex flex-col w-[70%] h-[70%] justify-start items-center border-[1px] border-[#5b5b5b] rounded relative">
          <CodeEditorWindow
            code={defaultCode}
            language={language}
            options={options}
          />
          <div className="absolute bottom-[-25px] right-2">
            {links.map((link, index) => (
              <a
                key={index}
                href={link.url}
                className="text-[#cfcfcf] mx-1 cursor-pointer  hover:text-[#ff1c71] hover:underline transition-colors duration-300 ff-monospace text-[0.65rem]"
                title={link.title}
                alt={link.title}
              >
                {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseLanding;
