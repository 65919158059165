import React from "react";
import Seo from "../Seo";
import BaseLanding from "./BaseLanding";

const TsLanding = () => {
  const language = "typescript";
  const defaultCode = `function main(): void {
    const url: string = "ts.okboomer.sh";
    const desc: string = "A fast and zero-persistence typescript note taking";
    const author: string = "@okboomersh ⚡";

    console.log("URL:", url);
    console.log("Descripción:", desc);
    console.log("Autor:", author);
}

main();`;

  return (
    <>
      <Seo
        title="Typescript Online Editor ~ okboomer.sh"
        desc="A quick and online typescript note taking. Nothing more. With it you can take ts notes without persistence. Give it a try bro!"
        keywords="ts, online, typescript editor, note, note taking"
        canonical="https://ts.okboomer.sh/"
        code="ts"
      />
      <BaseLanding defaultCode={defaultCode} language={language} />
    </>
  );
};
export default TsLanding;
