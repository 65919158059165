import React from "react";
import Seo from "../Seo";
import BaseLanding from "./BaseLanding";

const JsonLanding = () => {
  const language = "json";
  const defaultCode = `{
    "url": "json.okboomer.sh",
    "desc": "A fast and zero-persistence json note taking",
    "author": "@okboomersh ⚡"
}`;

  return (
    <>
      <Seo
        title="JSON Online Editor ~ okboomer.sh"
        desc="A quick and online json note taking. Nothing more. With it you can take json notes without persistence. Give it a try bro!"
        keywords="json, online, json editor, note, note taking"
        canonical="https://json.okboomer.sh/"
        code="json"
      />
      <BaseLanding defaultCode={defaultCode} language={language} />
    </>
  );
};
export default JsonLanding;
