import React from "react";
import { Helmet } from "react-helmet";

const Seo = ({ title, desc, keywords, canonical, code }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonical}></link>

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={`/images/${code}.jpeg`} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:domain" content={canonical} />
        <meta name="twitter:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta property="og:url" content={canonical} />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="720" />
        <meta property="og:image:height" content="720" />
        <meta property="og:image" content={`/images/${code}.jpeg`} />
      </Helmet>
    </>
  );
};

export default Seo;
