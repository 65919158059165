import React from "react";
import Seo from "../Seo";
import BaseLanding from "./BaseLanding";

const PyLanding = () => {
  const language = "python";
  const defaultCode = `#!/usr/bin/env python3
# -*- coding: utf-8 -*-

def main():
    url = "py.okboomer.sh"
    desc = "A fast and zero-persistence python note taking"
    author = "@okboomersh ⚡"

    print("URL:", url)
    print("Desc:", desc)
    print("Author:", author)

if __name__ == "__main__":
    main()
`;

  return (
    <>
      <Seo
        title="Python Online Editor ~ okboomer.sh"
        desc="A quick and online python note taking. Nothing more. With it you can take py notes without persistence. Give it a try bro!"
        keywords="py, online, python editor, note, note taking"
        canonical="https://py.okboomer.sh/"
        code="py"
      />
      <BaseLanding defaultCode={defaultCode} language={language} />
    </>
  );
};
export default PyLanding;
