import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  JsonLanding,
  PyLanding,
  TsLanding,
  TxtLanding,
  JsLanding,
  MdLanding,
  EditorsLanding,
} from "./components/Landings";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const hostname = url.hostname;
    const slug = url.pathname;

    if (hostname === "md.okboomer.sh") {
      navigate("/md");
    } else if (hostname === "json.okboomer.sh") {
      navigate("/json");
    } else if (hostname === "py.okboomer.sh") {
      navigate("/py");
    } else if (hostname === "ts.okboomer.sh") {
      navigate("/ts");
    } else if (hostname === "txt.okboomer.sh") {
      navigate("/txt");
    } else if (hostname === "js.okboomer.sh") {
      navigate("/js");
    } else if (hostname === "localhost" && slug === "/") {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/json/*" element={<JsonLanding />} />
      <Route path="/py/*" element={<PyLanding />} />
      <Route path="/ts/*" element={<TsLanding />} />
      <Route path="/txt/*" element={<TxtLanding />} />
      <Route path="/js/*" element={<JsLanding />} />
      <Route path="/md/*" element={<MdLanding />} />
      <Route path="/" element={<EditorsLanding />} />
    </Routes>
  );
}

function Wrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default Wrapper;
