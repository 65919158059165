import React from "react";
import Seo from "../Seo";
import BaseLanding from "./BaseLanding";

const TxtLanding = () => {
  const language = "text";
  const defaultCode = `txt.okboomer.sh
----------------    
    A fast and zero-persistence text note taking
    Developed by: @okboomersh ⚡
`;

  return (
    <>
      <Seo
        title="Text Online Editor ~ okboomer.sh"
        desc="A quick and online text note taking. Nothing more. With it you can take txt notes without persistence. Give it a try bro!"
        keywords="txt, online, text editor, note, note taking"
        canonical="https://txt.okboomer.sh/"
        code="txt"
      />
      <BaseLanding defaultCode={defaultCode} language={language} />
    </>
  );
};
export default TxtLanding;
